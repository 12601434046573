<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
    >
      <b-card>
        <div class="d-flex align-items-center">
          <b-form-select
            v-model="selectedYear"
            :options="years"
          />
          <b-button
            variant="primary"
            class="mx-1"
            @click="changeTypeCurrency"
          >
            <span class="text-nowrap">Cambiar reporte a {{ titleButton }}</span>
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      md="12"
    />
    <b-col
      cols="12"
      md="12"
    >
      <echart-bar-sale-seller
        v-if="showComponent"
        :series-seller="sellerMonthData"
        :year="selectedYear"
        :currency-symbol="currency === 'pen' ? 'S/.' : '$'"
      />
      <LoadingEmptyContent v-else />
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <report-sale-seller-by-year
        v-if="showComponentSaleByYear"
        :total-sale-by-seller="totalSaleBySeller"
      />
      <LoadingEmptyContent v-else />
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <report-commission-seller-by-year
        v-if="showComponentCommisionByYear"
        :total-commission-by-seller="totalCommissionBySeller"
      />
      <LoadingEmptyContent v-else />
    </b-col>

    <b-col
      cols="12"
      md="12"
    >
      <b-card>
        <b-card-title class="mb-1">
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Vendedor"
                label-for="seller"
              >
                <v-select
                  v-model="sellerId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sellers"
                  label="seller"
                  :reduce="val => val.id"
                  input-id="seller"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-title>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <ReportSaleByMonth
              v-if="showComponentCommisionByYear"
              :items-month="reportSaleSellerMonths"
            />
            <LoadingEmptyContent v-else />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <report-commission-seller-by-month
              v-if="showComponentCommisionByYear"
              :total-commission-by-month="totalCommissionByMonth"
            />
            <LoadingEmptyContent v-else />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BRow, BCard, BFormSelect, BButton, BFormGroup, BCardTitle,
} from 'bootstrap-vue'

import LoadingEmptyContent from '@core/components/loading/LoadingEmptyContent'
import EchartBarSaleSeller from '@/views/dramox/sale/components/charts/EchartBarSaleSeller'
import ReportSaleSellerByYear from '@/views/dramox/sale/components/ReportSaleSellerByYear'
import ReportCommissionSellerByYear from '@/views/dramox/sale/components/ReportCommissionSellerByYear'
import ReportCommissionSellerByMonth from '@/views/dramox/sale/components/ReportCommissionSellerByMonth'
import ReportSaleByMonth from '@/views/dramox/sale/components/ReportSaleByMonth'
import vSelect from 'vue-select'
// const colors = ['#ffe802', '#FDAC34', '#299AFF', '#4F5D70', '#2c9aff', '#84D0FF']

export default {
  components: {
    ReportSaleByMonth,
    ReportCommissionSellerByMonth,
    ReportCommissionSellerByYear,
    ReportSaleSellerByYear,
    EchartBarSaleSeller,
    LoadingEmptyContent,
    // ReportSaleByCategory,
    // ReportSaleByMonth,
    BRow,
    BCol,

    BCard,
    BFormSelect,
    BButton,
    BFormGroup,
    BCardTitle,
    vSelect,
  },
  data() {
    return {
      sellerId: null,
      selectedYear: new Date().getFullYear(),
      years: [],
      currency: 'pen',
      reportMonths: [],
      showComponent: true,
      showComponentSaleByYear: true,
      showComponentCommisionByYear: true,
      sellerMonthData: [],
      totalSaleBySeller: {
        sellers: [],
        total: 0,
      },
      totalCommissionBySeller: {
        sellers: [],
        total: 0,
      },
      totalCommissionByMonth: {
        commissions: [],
        total: 0,
      },
      reportSaleSellerMonths: {
        months: [],
        total: 0,
      },
      sellers: [],
    }
  },
  computed: {
    titleButton() {
      if (this.currency === 'pen') return 'dolares'
      return 'soles'
    },
  },
  watch: {
    selectedYear() {
      this.fetchReportMonth()
      this.fetchReportSaleSellerByYear()
      this.fetchReportSaleSellerByMonth()
      this.fetchReportCommissionSellerByYear()
      this.fetchReportCommissionSellerByMonth()
    },
    currency() {
      this.fetchReportMonth()
      this.fetchReportSaleSellerByYear()
      this.fetchReportSaleSellerByMonth()
      this.fetchReportCommissionSellerByYear()
      this.fetchReportCommissionSellerByMonth()
    },
    sellerId() {
      this.fetchReportSaleSellerByMonth()
      this.fetchReportCommissionSellerByMonth()
    },
  },
  mounted() {
    this.loadYears()
    this.fetchSellers()
    this.fetchReportMonth()
    this.fetchReportSaleSellerByYear()
    this.fetchReportSaleSellerByMonth()
    this.fetchReportCommissionSellerByYear()
    this.fetchReportCommissionSellerByMonth()
  },
  methods: {
    async fetchSellers() {
      try {
        const response = await this.$http.get('admin/sales/sellers')
        this.sellers = response.data.payload.results
      } catch (e) {
        console.log(e)
      }
    },
    async fetchReportMonth() {
      try {
        this.showComponent = false
        const response = await this.$http.get('admin/report/seller/sale-month', {
          params: {
            currency: this.currency,
            year: this.selectedYear,
          },
        })
        this.reportMonths = response.data.payload.results
        this.showComponent = true

        const datasetsData = []
        this.reportMonths.forEach(item => {
          datasetsData.push({
            name: item.name,
            type: 'bar',
            stack: 'advertising',
            data: item.data.map(e => e.total),
            barMaxWidth: '20%',
            barMinWidth: '10px',
          })
        })
        this.sellerMonthData = datasetsData
      } catch (e) {
        console.log(e)
      }
    },
    async fetchReportSaleSellerByYear() {
      try {
        this.showComponentSaleByYear = false
        const response = await this.$http.get('admin/report/seller/sale-year', {
          params: {
            currency: this.currency,
            year: this.selectedYear,
          },
        })
        this.totalSaleBySeller = response.data.payload.results
        this.showComponentSaleByYear = true
      } catch (e) {
        console.log(e)
      }
    },
    async fetchReportSaleSellerByMonth() {
      try {
        this.showComponentSaleByYear = false
        const response = await this.$http.get('admin/report/sale/seller/sale-month', {
          params: {
            currency: this.currency,
            year: this.selectedYear,
            sellerId: this.sellerId,
          },
        })
        this.reportSaleSellerMonths = response.data.payload.results
        this.showComponentSaleByYear = true
      } catch (e) {
        console.log(e)
      }
    },
    async fetchReportCommissionSellerByYear() {
      try {
        this.showComponentCommisionByYear = false
        const response = await this.$http.get('admin/report/seller/commission-year', {
          params: {
            currency: this.currency,
            year: this.selectedYear,
          },
        })
        this.totalCommissionBySeller = response.data.payload.results
        this.showComponentCommisionByYear = true
      } catch (e) {
        console.log(e)
      }
    },
    async fetchReportCommissionSellerByMonth(sellerId = null) {
      try {
        const response = await this.$http.get('admin/report/seller/commission-month', {
          params: {
            currency: this.currency,
            year: this.selectedYear,
            sellerId,
          },
        })
        this.totalCommissionByMonth = response.data.payload.results
      } catch (e) {
        console.log(e)
      }
    },
    loadYears() {
      const date = new Date()
      let base = date.getFullYear()
      for (base; base >= 2016; base--) {
        this.years.push(base)
      }
    },
    changeTypeCurrency() {
      if (this.currency === 'pen') {
        this.currency = 'usd'
      } else {
        this.currency = 'pen'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.echarts{
  width: 100% !important;
}
</style>
