<template>
  <b-card>
    <b-card-title class="mb-1">
      COMISIONES DE VENDEDORES POR AÑO
    </b-card-title>
    <b-table
      responsive="sm"
      :fields="tableColumns"
      :items="totalCommissionBySeller.sellers"
    />
    <b-card-footer class="text-right">
      <h4>Total: {{ totalCommissionBySeller.total }} </h4>
    </b-card-footer>
    <echart-doughnut-sale-seller-year
        v-if="totalCommissionBySeller.sellers.length > 0"
        :series-data="seriesData"
        :total="totalCommissionBySeller.total"
        title-chart="Comisiones anuales por vendedor"
    />
  </b-card>
</template>

<script>
import {
  BTable, BCard, BCardTitle, BCardFooter,
} from 'bootstrap-vue'
import EchartDoughnutSaleSellerYear from '@/views/dramox/sale/components/charts/EchartDoughnutSaleSellerYear.vue'

export default {
  components: {
    EchartDoughnutSaleSellerYear,
    BTable,
    BCard,
    BCardTitle,
    BCardFooter,
  },
  props: {
    totalCommissionBySeller: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'seller', label: 'Vendedor', sortable: false },
        {
          key: 'commission_format',
          label: 'Total',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      items: [],
    }
  },
  computed: {
    seriesData() {
      return this.totalCommissionBySeller.sellers.map(item => ({
        value: item.commission,
        name: item.seller,
      }))
    },
  },
}
</script>

<style scoped>

</style>
