<template>
  <b-card>
    <b-card-title class="mb-1">
      VENTAS DE VENDEDORES POR AÑO
    </b-card-title>
    <b-table
      responsive="sm"
      :fields="tableColumns"
      :items="totalSaleBySeller.sellers"
    />
    <b-card-footer class="text-right">
      <h4>Total: {{ totalSaleBySeller.total }} </h4>
    </b-card-footer>
    <echart-doughnut-sale-seller-year
      v-if="totalSaleBySeller.sellers.length > 0"
      :series-data="seriesData"
      :total="totalSaleBySeller.total"
      title-chart="Ventas anuales por vendedor"
    />
  </b-card>
</template>

<script>
import {
  BTable, BCard, BCardTitle, BCardFooter,
} from 'bootstrap-vue'
import EchartDoughnutSaleSellerYear from '@/views/dramox/sale/components/charts/EchartDoughnutSaleSellerYear.vue'

export default {
  components: {
    EchartDoughnutSaleSellerYear,
    BTable,
    BCard,
    BCardTitle,
    BCardFooter,
  },
  props: {
    totalSaleBySeller: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'seller', label: 'Vendedor', sortable: false },
        {
          key: 'total_format',
          label: 'Total',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      items: [],
    }
  },
  computed: {
    seriesData() {
      return this.totalSaleBySeller.sellers.map(item => ({
        value: item.total,
        name: item.seller,
      }))
    },
  },
}
</script>

<style scoped>

</style>
