<template>
  <b-card title="">
    <div class="pie-text text-center">
      <h5 class="font-weight-bolder">
        {{ total }}
      </h5>
      <span class="font-weight-bold">Total</span>
    </div>

    <!-- echart -->
    <app-echart-doughnut :series="series" />

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'

export default {
  components: {
    BCard,
    AppEchartDoughnut,
  },
  props: {
    seriesData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: String,
      required: true
    },
    titleChart: {
      type: String,
      required:true
    }
  },
  data() {
    return {
      series: [
        {
          name: this.titleChart,
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          // data: [
          //   { value: 335, name: 'Point One' },
          //   { value: 310, name: 'Point Two' },
          //   { value: 234, name: 'Point Three' },
          //   { value: 435, name: 'Point Four' },
          // ],
          data: this.seriesData
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
  }
</style>
