<template>
  <b-card :title="`Resumen de ventas mensuales del año ${year}`">
    <app-echart-bar
      :option-data="option"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  props: {
    seriesSeller: {
      type: Array,
      default: () => [],
    },
    year: {
      type: Number,
      required: true,
    },
    currencySymbol: {
      type: String,
      default: 'S/.',
    },
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            type: 'category',
            data: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            axisLabel: {
              formatter: `${this.currencySymbol} {value}`,
              align: 'left',
              // ...
            },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
        series: this.seriesSeller,
      },
    }
  },
}
</script>
